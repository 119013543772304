const links = document.querySelectorAll(".navigation__link");
links.forEach(link => {
  link.addEventListener("click", () => {
    const ref = link.getAttribute("reference");
    const offset = document.getElementById(ref).offsetTop;
    
    const buffer = window.innerWidth < 600 ? 100 : 50;
    const scrollTo = offset + window.innerHeight - buffer;

    window.scroll({ top: scrollTo, left: 0, behavior: "smooth" });

    const burger = document.querySelector(".burger");
    const navigation = document.querySelector(".navigation");
    if (burger && navigation) {
      burger.classList.remove("burger--open");
      navigation.classList.remove("navigation--open");
    }
  });
});
