const form = document.getElementById("contact-form");
const formName = document.getElementById("name");
const email = document.getElementById("email");
const request = document.getElementById("request");

grecaptcha.ready(function() {
  grecaptcha
    .execute("6Lc3CM0UAAAAANsR35LyRXMxFSMPAB-BNbEZxY5c", { action: "MyForm" })
    .then(function(token) {
      window.token = token;
    });
});

form.addEventListener("submit", e => {
  e.preventDefault();

  const container = document.querySelector(".footer__contact-form");
  if (container) {
    container.classList.add("footer__contact-form--loading");
  }

  if (!formName || !email || !request) return false;

  const xhr = new XMLHttpRequest();
  const url = window.location.href.split("?")[0] + "?async=1&postForm=1";
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send(
    `name=${formName.value}&email=${email.value}&request=${request.value}&grecaptcha=${window.token}`
  );

  xhr.onreadystatechange = e => {
    if (container) {
      container.classList.remove("footer__contact-form--loading");
    }

    formName.value = "";
    email.value = "";
    request.value = "";

    alert("Ihre Anfrage wurde erfolgreich versendet");
    xhr.onreadystatechange = null;
  };
});
