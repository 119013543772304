const wHeight = window.innerHeight;
const headerTitle = document.querySelector(".header__inner");
const headerOverlay = document.querySelector(".header__scroll-overlay");
const navbar = document.querySelector(".navbar");

window.addEventListener("scroll", () => {
  setOpacityOfTitle();
  stickyNav();
});

window.addEventListener("load", () => {
  setOpacityOfTitle();
  stickyNav();
});

const setOpacityOfTitle = () => {
  const scrollTop = document.querySelector("html").scrollTop;
  const opacityTitle = 1 - scrollTop / (wHeight - 75);
  const opacityOverlay = scrollTop / (wHeight - 75);
  if (headerTitle) {
    headerTitle.style.opacity = opacityTitle;
    headerOverlay.style.opacity = opacityOverlay;
    if(opacityTitle < 0){
      headerTitle.style.display = "none";
    }else{
      headerTitle.style.display = "block";
    }
  }
};

const stickyNav = () => {
  if (!navbar) return false;
  const scrollTop = document.querySelector("html").scrollTop;
  if (scrollTop >= window.innerHeight - 75) {
    navbar.classList.add("navbar--fixed");
  } else {
    navbar.classList.remove("navbar--fixed");
  }
};
